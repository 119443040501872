import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RayTable, AdminProductACQListHeader, RayChartBar } from '../common';
import { NoRecord } from '../../pages/HomePage';
import { Translate as t } from '../../config/Translate';
import { ProductAction } from '../../actions';
import { ProductConstant } from '../../actions/constants/ProductConstant';

const ProductInfoACQ = ({ product, showtable = true, days = 14, height = 400, onlyGraph = false, GetProductAcq, GetProductAcqChart, acq, page, sort, acqchart }) => {

	const isAdmin = location.pathname.toLowerCase().startsWith('/admin/');
	const [chartData, setChartData] = useState([]);
	const [hasChartData, setHasChartData] = useState(true);
	const [chartCategory, setChartCategory] = useState([]);
	const [chartFilter, setChartFilter] = useState({
		date: "", modality: ""
	});
	const [payloads, setPayloads] = useState({});
	const dispatch = useDispatch();

	const filterpage = {
		filters: {},
		searchKey: "",
		page: page || 1,
		itemperpage: 30
	};

	const options = {
		search: false,
		keyCol: "_id",
		filterpageoption: {
			filterDateOptions: [
				
			],
			page: 1,
			itemperpage: 30,
			filters: {},
			sn: product.sn
		},
		groupFilter: true,
		getList: GetProductAcq,
		itemperpage: 30,
		useapi: true,
		filters: {},
		totalitemcount: product.cnt_acquisitions || 0,
	}

	useEffect(() => {
		dispatch({ type: ProductConstant.CLEAR_PRODUCT_ACQ_INFO })
	}, []);

	useEffect(async () => {
		if((product?.cnt_acquisitions || 0) != 0) {
			await GetProductAcq(options.filterpageoption, product.sn);
			await GetProductAcqChart(product.sn);
		}
	}, [product]);

	useEffect(() => {
		makeData();
	}, [acqchart])

	const makeData = () => {
		var category = [];
		if (!acq.acquisitions)
			return;
		acq.acquisitions.map((x) => {
			if (!category.includes(x.modality))
				category.push(x.modality)
		})
		setChartCategory(category);

		var result = [];
		for (var i = days - 1; i >= 0; i--) {
			var localDate = moment().add(-1 * i, 'days');
			var obj = { date: localDate.format("MM/DD"), odate: localDate.format("YYYY-MM-DD") };
			category.map(m => {
				obj[m] = 0;
			});
			result.push(obj)
		}
		acq.acquisitions.map((value) => {
			var localDate = moment.utc(value.activationTime).local().format("YYYY-MM-DD");
			var foundIndex = result.findIndex(x => x.odate == localDate);
			if (result[foundIndex])
				result[foundIndex][value.modality]++;
		});
		setChartData(result);
		setHasChartData(acq.acquisitions.filter(x => x.activationTime > moment().add(-1 * days, 'days')).length > 0)
	}

	const handleClick = (item) => {
		setChartFilter({
			date: item.data.odate,
			modality: item.id
		});
		console.log(item);
	}

	const chartOptions = {
		height: height,
		handleClick: handleClick
	}

	const mapList = () => {
		return acq.acquisitions;
	}

	return (
		<>
			{acq.acquisitions &&
				<>
					{isAdmin &&
						<>
							{acq.acquisitions.length > 0 &&
								<>
									{(onlyGraph && hasChartData) && <RayChartBar data={chartData} category={chartCategory} indexBy="date" options={chartOptions} />}
									{!onlyGraph && <div className="section">
										{hasChartData &&
											<div className="card mb-1">
												<RayChartBar data={chartData} category={chartCategory}
													indexBy="date" options={chartOptions} />
											</div>}
										{(showtable && acq.acquisitions.length > 0) &&
											<div className="card">
												<div className="card-body">
													<RayTable
														data={mapList()}
														columns={AdminProductACQListHeader}
														options={options} 
														payloadCallback={(pl) => setPayloads(pl)}
														/>
												</div>
											</div>
										}
									</div>}
								</>
							}
							{(acq.acquisitions.length === 0 && !onlyGraph) &&
								<div className="section">
									<NoRecord title={t('norecords')} contents={t('norecordsfound')} />
								</div>
							}
						</>
					}
					{!isAdmin &&
						<>
							{acq.acquisitions.filter(x => x.activationTime > moment().add(-1 * days, 'days')).length > 0 &&
								<>
									{onlyGraph && <RayChartBar data={chartData} category={chartCategory} indexBy="date" options={chartOptions} />}
									{!onlyGraph && <div className="section">
										<div className="card mb-1">
											<RayChartBar data={chartData} category={chartCategory}
												indexBy="date" options={chartOptions} />
										</div>
										{(showtable && acq.acquisitions.length > 0) &&
											<div className="card">
												<div className="card-body">
													<RayTable
														data={mapList()}
														columns={AdminProductACQListHeader}
														options={options} />
												</div>
											</div>
										}
									</div>}
								</>
							}
							{(acq.acquisitions.filter(x => x.activationTime > moment().add(-1 * days, 'days')).length === 0 && !onlyGraph) &&
								<div className="section">
									<NoRecord title={t('norecords')} contents={t('norecordsfound')} />
								</div>
							}
						</>
					}
				</>
			}
		</>
	);
};

const mapState = (state) => {
	const acq = state.ProductReducer.acq;
	var page = state.ProductReducer.page;
	var sort = state.ProductReducer.sort;
	const acqchart = state.ProductReducer.acqchart;
	return { acq, page, sort, acqchart };
};

const mapDispatch = (dispatch) => ({
	GetProductAcq: (filter, sn) => dispatch(ProductAction.GetProductAcq(filter, sn)),
	GetProductAcqChart: (sn) => dispatch(ProductAction.GetProductAcqChart(sn))
});

export default connect(mapState, mapDispatch)(ProductInfoACQ);