import React, { useState, useEffect } from "react";
import { render } from "react-dom";
//import './App.css';

const RayGoogleMaps = ({onMapLoad, options, height, Lat, Lng, markerList, callback, callbackAddress}) => {

    useEffect(() => {
        onScriptLoad();
    },[Lat, Lng]);

    const onScriptLoad = async () => {
        var card = document.getElementById('pac-card');
        var input = document.getElementById('pac-input');
        input.addEventListener("keypress", (e) => {
            if (e.key === "Enter") {
                e.preventDefault(); // Prevent form submission
                searchPlace(input.value);
            }
        });

        const position = { lat: Lat, lng: Lng }
        const map = new window.google.maps.Map(
            document.getElementById("myMapGoogle"),
            options,
            options.center = position
        );
        
        if(!map){
            callback(false);
            return;
        }else{
            callback(true);
        }
        
        var marker = new window.google.maps.Marker({
            position: position,
            anchorPoint: new window.google.maps.Point(0, -29),
            map: map
        });

        map.addListener('click', function (e) {
            marker.setMap(null);
            placeMarkerAndPanTo(e.latLng, map);
        });

        // Marker marked function
        function placeMarkerAndPanTo(latlng, map) {
            marker = new window.google.maps.Marker({
                position: latlng,
                map: map
            });
            marker.addListener('click', function () {
                graphAddress();
            });
            map.panTo(latlng);
            graphAddress();
        }

        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(card);
        
        
        var placesService = new window.google.maps.places.PlacesService(map);

        function searchPlace(query) {
            if (!query) return;

            const request = {
                query: query,
                fields: ['formatted_address', 'geometry', 'name'],
            };

            // Use PlacesService to find a place by query
            placesService.findPlaceFromQuery(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    // Update map with search result
                    const place = results[0];
                    map.setCenter(place.geometry.location);

                    new google.maps.Marker({
                        map,
                        position: place.geometry.location,
                        title: place.name,
                    });

                    if (!place.geometry) {
                        window.alert("No details available for input: '" + place.name + "'");
                        return;
                    }
        
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                        map.setZoom(17);
                    }
                    marker.setPosition(place.geometry.location);
                    marker.setVisible(true);
        
                    var address = '';
                    if (place?.formatted_address) {
                        // address = [
                        //   ((place.address_components[0] && place.address_components[0].short_name) || ''),
                        //   ((place.address_components[1] && place.address_components[1].short_name) || ''),
                        //   ((place.address_components[2] && place.address_components[2].short_name) || ''),
                        //   ((place.address_components[3] && place.address_components[3].short_name) || '')
                        
                        // ].join(' ');
                        address = place?.formatted_address;
                    }
        
                    for (var i = 0; i < place?.address_components?.length; i++) {
                        for (var j = 0; j < place.address_components[i].types.length; j++) {
                            if (place.address_components[i].types[j] === "postal_code") {
                                // zipcode
                                console.log(place.address_components[i].long_name);
                            }
        
                            if (place.address_components[i].types[j] === "country") {
                                // Country Code            
                                console.log(place.address_components[i].short_name);
                            }
                        }
                    }
                    callbackAddress && callbackAddress({
                        lat : marker.position.lat(),
                        lng : marker.position.lng(),
                        address : address,
                    });

                    console.log("Place details:", place);
                } else {
                    alert("Place not found: " + status);
                }
            });
        }



        // var autocomplete = new window.google.maps.places.Autocomplete(input);
        // autocomplete.bindTo('bounds', map);
        // autocomplete.setFields(
        //     ['address_components', 'geometry', 'icon', 'name']
        // );
        // autocomplete.addListener('place_changed', function () {
        //     marker.setVisible(false);
        //     var place = autocomplete.getPlace();
        //     if (!place.geometry) {
        //         window.alert("No details available for input: '" + place.name + "'");
        //         return;
        //     }

        //     if (place.geometry.viewport) {
        //         map.fitBounds(place.geometry.viewport);
        //     } else {
        //         map.setCenter(place.geometry.location);
        //         map.setZoom(17);
        //     }
        //     marker.setPosition(place.geometry.location);
        //     marker.setVisible(true);

        //     var address = '';
        //     if (place.address_components) {
        //         address = [
        //           ((place.address_components[0] && place.address_components[0].short_name) || ''),
        //           ((place.address_components[1] && place.address_components[1].short_name) || ''),
        //           ((place.address_components[2] && place.address_components[2].short_name) || ''),
        //           ((place.address_components[3] && place.address_components[3].short_name) || '')
                
        //         ].join(' ');
        //     }

        //     for (var i = 0; i < place.address_components.length; i++) {
        //         for (var j = 0; j < place.address_components[i].types.length; j++) {
        //             if (place.address_components[i].types[j] === "postal_code") {
        //                 // zipcode
        //                 console.log(place.address_components[i].long_name);
        //             }

        //             if (place.address_components[i].types[j] === "country") {
        //                 // Country Code            
        //                 console.log(place.address_components[i].short_name);
        //             }
        //         }
        //     }
        //     callbackAddress && callbackAddress({
        //         lat : marker.position.lat(),
        //         lng : marker.position.lng(),
        //         address : address,
        //     });
        // })

        function graphAddress() {
            var geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ 'latLng': marker.getPosition() }, function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        var address = results[0].formatted_address;
                    
                        if (results[0].address_components) {
                            address = [
                              ((results[0].address_components[0] && results[0].address_components[0].short_name) || ''),
                              ((results[0].address_components[1] && results[0].address_components[1].short_name) || ''),
                              ((results[0].address_components[2] && results[0].address_components[2].short_name) || ''),
                              ((results[0].address_components[3] && results[0].address_components[3].short_name) || '')
                            ].join(' ');
                        }
                    
                        for (var i = 0; i < results[0].address_components.length; i++) {
                            for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                                if (results[0].address_components[i].types[j] === "postal_code") {
                                    console.log(results[0].address_components[i].long_name);
                                }
                            
                                if (results[0].address_components[i].types[j] === "country") {
                                    // [Country]
                                    console.log(results[0].address_components[i].short_name);
                                }
                            }
                        }
                    
                        // [postal_code]
                        console.log(address);
                        // 위도, 경도
                        console.log(marker.position.lat());
                        console.log(marker.position.lng());
                    }
                }
            });
        }

        if (markerList) {
            const markers = markerList.map((location) => {
            return new window.google.maps.Marker({
              position: location
              //map: map
            });
          });
          new window.MarkerClusterer(map, markers, {
          imagePath:
            'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          });
        }
        
        onMapLoad(map);
    }

    return <div style={{ height: height }} id="myMapGoogle" />
}

const RayCNMaps = ({height, Lat, Lng, callback}) => {
    useEffect(() => {
        onScriptLoad();
    },[Lat, Lng]);

    const onScriptLoad = async() => {
        var bmapPoint, marker, myValue;
        const BMap = window.BMap;
        if(!BMap){
            callback(false);
            return;
        }else{
            callback(true);
        }
        var map_CN = new BMap.Map(
            document.getElementById("myMap")
        );
        map_CN.enableScrollWheelZoom(true);
        map_CN.centerAndZoom(new BMap.Point(Lng, Lat), 18); // 初始化地图,设置中心点坐标和地图级别
        //map_CN.setCurrentCity("成都"); // 设置地图显示的城市 此项是必须设置的
        bmapMarker(new BMap.Point(Lng, Lat));
        map_CN.addEventListener('click', function (e) {
            var lngs = e.point.lng;
            var lats = e.point.lat;
            bmapPoint = new BMap.Point(lngs, lats);
            bmapMarker(bmapPoint);
            bmapAddress(bmapPoint);
        })
        function bmapMarker(point) {
            //G("pac-input").value = "";
            map_CN.clearOverlays();
            map_CN.removeOverlay(marker);
            marker = new BMap.Marker(point);
            map_CN.addOverlay(marker);
            map_CN.panTo(point);
        }
        function bmapAddress(point) {
            var geoc = new BMap.Geocoder();
            geoc.getLocation(point, function (rs) {
                var addComp = rs.addressComponents;
                var addr = addComp.city + "" + addComp.district + "" + addComp.street + "" + addComp.streetNumber;
                console.log(addr);
                console.log(rs.point.lat);
                console.log(rs.point.lng);
            });
        }

        var ac;
        ac = new BMap.Autocomplete({
            "input": "pac-input",
            "location": map_CN
        });
    
        ac.addEventListener("onhighlight", function (e) {
            var str = "";
            var _value = e.fromitem.value;
            var value = "";
            if (e.fromitem.index > -1) {
                value = _value.city + _value.district + _value.street + _value.streetNumber + _value.business;
            }
            str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
    
            value = "";
            if (e.toitem.index > -1) {
                _value = e.toitem.value;
                value = _value.city + _value.district + _value.street + _value.streetNumber + _value.business;
            }
            str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
            document.getElementById("searchResultPanel").innerHTML = str;
        });
    
        ac.addEventListener("onconfirm", function (e) {
            var _value = e.item.value;
            myValue = _value.city + _value.district + _value.street + _value.streetNumber + _value.business;
            document.getElementById("searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
            console.log(myValue);
            setPlace();
        });

        function setPlace() {
            var local = new BMap.LocalSearch(map_CN, {
                onSearchComplete: function (results) {
                    if(local.getStatus() === 0) {
                        var pp = local.getResults().getPoi(0).point;
                        console.log(pp.lat);
                        console.log(pp.lng);
                        map_CN.centerAndZoom(pp, 18);
                        bmapMarker(pp);
                    }
                }
            });
            local.search(myValue);
        }
    }

    return <div style={{ height: height }} id="myMap" />
}

const RayMaps = ({onMapLoad, options, country, Lat, Lng, height = 300, markerList, callback, callbackAddress}) => {

    return  (
        <div>
            <div className="pac-card" id="pac-card" style={{zIndex:0, position:"absolute", right: 0}}>
                <div id="pac-container">
                    <input id="pac-input" type="text" 
                        placeholder="Enter a location" 
                        style={{ width : 420 }}
                        className='form-control form-control-sm' />
                </div>
            </div>
            <div id="searchResultPanel" style={{zIndex:20, position:"relative", border:1 || "solid" || "#C0C0C0", width:150, height:"auto", display:"none"}}></div>
            <div id="infowindow-content" style={{ width: 200, height: 100, display:"none" }}>
                <img src="" width="16" height="16" id="place-icon" alt="info"/>
                <span id="place-name" className="title"></span><br />
                <span id="place-address"></span>
            </div>
            {country != "CN" && <RayGoogleMaps 
                onMapLoad={onMapLoad} 
                callback={callback}
                callbackAddress={callbackAddress}
                height={height} 
                Lat={Number(Lat)} 
                Lng={Number(Lng)} 
                options={options} />}
            {country == "CN" && <RayCNMaps 
                callback={callback}
                height={height} 
                Lat={Number(Lat)} 
                Lng={Number(Lng)} 
                options={options} />}
        </div>
    );
}

export default RayMaps;
export {
    RayGoogleMaps
}