import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { history } from '../../history';
import { ProductAction, AlertAction, RayCodeAction } from '../../actions';
import {
	ProductDelete,
	ProductInfoACQ,
	ProductInfoError,
	ProductInfoWarranty,
	ProductChangeSN,
} from '../../components/product'
import AdminProductUpdateSite from './AdminProductUpdateSite';
import ProductInfoAlignment from '../../components/product/ProductInfoAlignment';
import ProductInfoQC from '../../components/product/ProductInfoQC';
import ProductInfoAutoDiagnosis from '../../components/product/ProductInfoAutoDiagnosis';
import ServiceAdminList from '../../components/service/ServiceAdminList';
import { isDOA, isDIM, isCon } from '../../components/common/CommonViews';
import ProductInfoSummary from '../../components/product/ProductInfoSummary';
import ProductInfoExtra from '../../components/product/ProductInfoExra';
import ProductHistoryReport from '../../components/product/ProductHistoryReport';
import moment from 'moment';
import { Translate as t } from '../../config/Translate';
import { Loading } from '../../components/common/Loading';

const AdminProductInfo = ({ GetProduct, GetProductOld, product, isstock, orisninfo, errmessage, user, clist }) => {

	const { sn, view } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [tab, setTab] = useState(view || "info");
	const [codes, setCodes] = useState([]);
	const [editable, setEditable] = useState(false);	
	const [getPInfoErr, setgetPInfoErr] = useState("");
	const [showReport, setShowReport] = useState(false);
	const [newTabList, setNewTabList] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		console.log('AdminProductInfo -> ', clist?.filter(x => x.type === 'PRODUCTOPTION'));
		setgetPInfoErr("");		
		setCodes(clist?.filter(x => x.type === 'PRODUCTOPTION'));
		//setCodes(await RayCodeAction.GetAllByFilter({ type: "PRODUCTOPTION" }));
	}, [clist])

	useEffect(() => {
		return () => {
			setgetPInfoErr(errmessage == "EP0009" ? "Doest not exist product" : "");
			dispatch(AlertAction.Clear());
		}
	}, [errmessage])

	useEffect(() => {
		setIsLoading(false);
	}, [getPInfoErr])

	useEffect(() => {
		setTab(view || "info")
	}, [view])

	useEffect(() => {
		if (!product.old) {
			setIsLoading(sn != product.sn)
		} else {
			setIsLoading(false)
		}
	}, [product])

	useEffect(() => {
		sn != product.sn && GetProduct(sn);
	}, [])

	const getLengthStr = (objArr, objArr2 = null) => {
		if (objArr2) {
			if (objArr) {
				return objArr.length + objArr2.length > 0 ? "(" + (objArr.length + objArr2.length) + ")" : "";
			}
		} else {
			if (objArr) {
				return objArr.length > 0 ? "(" + objArr.length + ")" : "";
			}
		}
		return "";
	}

	const tabList = [
		{ code: "info", title: t('information'), showall : true },
		{ code: "service", title: t("service") + getLengthStr(product?.services), showall : true },
		{ code: "error", title: t('errors') + getLengthStr(product.errs), showall : true },
		{ code: "warranty", title: t('warranty'), showall : true },
		{ code: "qc", title: "QC" + getLengthStr(product.qcs), showall : false },
		{ code: "dia", title: t('autodiagnosis') + getLengthStr(product.autoDiagnoses), showall : false },
		{ code: "alignment", title: t('alignment') + getLengthStr(product.alignments, product.emsalignments), showall : false },
		//{ code: "acquisition", title: t('acquisition') + getLengthStr(product.acquisitions), showall : true },
		{ code: "acquisition", title: t('acquisition') + `${product?.cnt_acquisitions ? "(" + product?.cnt_acquisitions + ")" : "" }`, showall : true },
		{ code: "extra", title: t('details'), showall : true },
	]

	useEffect(() => {
		if(user?._id && user._id == "60d57a0d4f3e3a849e7a3c89" || user._id == "60d579bb2a4027020873e987") {
			tabList.push({ code: "del", title: t('delete')})
		}
		setNewTabList(tabList);
	}, [user, product])

	const createService = () => {
		history.push({
			pathname: '/Admin/AdminServiceAdd',
			state: {
				refer: 'PRODUCTERROR',
				item: {},
				extraItem: product,
			},
		});
	}

	const getOldInfo = (e) => {
		var oldsn = e.target.value;
		if (oldsn != "") {
			var oldsnobj = product.snhistories.filter(x => x.sn == oldsn)[0];
			GetProductOld(oldsn, oldsnobj);
		}
	}

	const goRealSn = () => {
		GetProduct(product.orisn);
	}

	const productReportshow = () => {
		if (showReport)
			setShowReport(false)
		else
			setShowReport(true)
	}

	const filter = (x) => {
		const taball = codes.find(x => x.group === product.model)?.category === 'Extra-oral Imaging';
		if(taball)
			return true;

		return x.showall;
	} 

	const rct600Download = (productSN) => {
		const curDate = new Date().getTime();
		const url = `https://guard-data.rayteams.com/devices/RCT600/${productSN}.zip?t=${curDate}`
		fetch(url)
		.then(response => {
		  	if (response.status === 200) {
				const newWindow = window.open(url, '_blank');
				if (!newWindow) {
					alert(t('팝업 차단이 활성화되어 새 창을 열 수 없습니다.'));
				}
		  	} else if (response.status === 403) {
				const sUrl = `https://guard-data.rayteams.com/devices/RCT600/${productSN}/${productSN}.zip?t=${curDate}`
				fetch(sUrl).then(resp => {
					if (resp.status === 200) {
						const newWindow = window.open(sUrl, '_blank');
						if (!newWindow) {
							alert(t('팝업 차단이 활성화되어 새 창을 열 수 없습니다.'));
						}
					} else {
						alert(t('파일이 존재하지 않거나 다운로드할 수 없습니다.'));		
					}
				})
		  	}
			else {
				alert(t('파일이 존재하지 않거나 다운로드할 수 없습니다.'));
		  	}
		})
		.catch(() => {
		  	alert('파일 다운로드 중 문제가 발생했습니다.');
		});
	};

	return (
		<>
			{(getPInfoErr) && <div className="extraHeader">
				<div className="alert alert-danger ms-1 me-2">{getPInfoErr}</div>
				<Link to={!isstock ? "/Admin/AdminProductList" : "/Admin/AdminProductStockList"}
					className="btn btn-outline-primary mt-0 me-1">Go List</Link>
			</div>}
			{(!isLoading && !getPInfoErr) &&
				<>
					<div className="extraHeader">
						<h2 className="mb-0">
							{!product.orisn && isCon(product, 20)}
							{product.sn}
							{isDOA(product)} {isDIM(product)}
							<span className="fs-13 text-danger ms-1">{product.model}</span>
							{(product.orisn) &&
								<span className="fs-13 alert alert-success ms-1">SN Changed - {orisninfo.reason} / {moment(orisninfo.updated).local().format('YYYY-MM-DD HH:mm:ss')} / {orisninfo.user}</span>
							}
							{user.adminType == "S" && ((!product.orisn && tab == "info") &&
							<button onClick={() => setEditable(!editable)} className="btn btn-secondary btn-sm mt-0 ms-1">
								<ion-icon name={editable ? "lock-open-outline" : "lock-closed-outline"} />
								{editable ? t('unlocked') : t('locked')}
							</button>
							)}
						</h2>
						<div className="ray-right">
							{product?.sn && product?.model && product.model.toUpperCase() === "RCT600" &&
								<button onClick={() => {
									console.log("RCT600 download", product.sn.toUpperCase()); 
									//rct600Download(`https://guard-data.rayteams.com/devices/RCT600/${product.sn.toUpperCase()}.zip`);
									rct600Download(product.sn.toUpperCase());
									}} className="btn btn-outline-info btn-sm mt-0 me-1">
									{'RCT 600 DownLoad'}
								</button>
							}
							{(!product?.orisn && product.snhistories?.length > 0 && !editable) && <select
								onChange={(x) => getOldInfo(x)}
								className="form-control form-control-sm float-start me-1" style={{ width: 120 }}>
								<option value="">SN History</option>
								{product.snhistories.map(x => <option value={x.sn}>{x.sn}</option>)}
							</select>}
							{product.orisn && <button className="btn btn-primary btn-sm mt-0" onClick={() => goRealSn()}>Now SN : {product.orisn}</button>}
							{!product.orisn && <>
								{
									(user.adminType == 'S' && user.companyId != 'RAY02091') && <Link to={"/Admin/AdminProductInfo/" + product.sn + "/changesn"} className="btn btn-danger btn-sm mt-0 me-1" onClick={() => setTab('changesn')}>{t('changesn')}</Link>
								}
								<button onClick={() => createService()} className="btn btn-outline-primary btn-sm mt-0 me-1">{t('addnewservice')}</button>
								
							</>}
							{user.adminType == "S" && (user?.companyId == "RAY00001") && <button className="btn btn-primary btn-sm mt-0" onClick={() => productReportshow()}>Product History</button>}
							{!product.orisn && <Link to={!isstock ? "/Admin/AdminProductList" : "/Admin/AdminProductStockList"} className="btn btn-outline-default btn-sm mt-0 ms-1">List</Link>}
						</div>
					</div>

					{!showReport &&
						<div id="appCapsule" className="extra-header-active full-height">
							<div className="section mt-1 mb-1">
								<ul className="nav nav-tabs style2 mt-1 mb-1">
									{newTabList.filter(filter).map(x => {
										if (x.code == "warranty" && product.orisn)
											return;
										return (
											<li className="nav-item" key={x.code}>
												{product.orisn && <a className={tab == x.code ? "nav-link active" : "nav-link"} onClick={() => setTab(x.code)} >{x.title}</a>}
												{!product.orisn && <Link to={"/Admin/AdminProductInfo/" + product.sn + "/" + x.code}
													className={tab == x.code ? "nav-link active" : "nav-link"}
													onClick={() => setTab(x.code)}>{x.title}</Link>}
											</li>
										)
									})}
								</ul>
							</div>
							{(tab == "info" && product.sn) && <ProductInfoSummary productInfo={product} editable={editable} codes={codes} cancelcallback={() => setEditable(false)} />}
							{tab == "acquisition" && product.sn && <ProductInfoACQ product={product} />}
							{tab == "error" && product.sn && <ProductInfoError product={product} />}
							{tab == "qc" && product.sn && <ProductInfoQC product={product} />}
							{tab == "alignment" && product.sn && <ProductInfoAlignment product={product} />}
							{tab == "dia" && product.sn && <ProductInfoAutoDiagnosis product={product} />}
							{(!product.orisn && tab == "warranty") && product.sn && <ProductInfoWarranty product={product} />}
							{tab == "del" && product.sn && <ProductDelete product={product} />}
							{tab == "changesn" && product.sn && <ProductChangeSN product={product} />}
							{tab == "service" && product.sn && <ServiceAdminList warranties={product.waranties} sn={product.sn} />}
							{tab == "updatesite" && product.sn && <AdminProductUpdateSite product={product} />}
							{tab == "extra" && product.sn && <ProductInfoExtra productInfo={product} editable={editable} />}

						</div>
					}
					{(showReport && product.sn) &&
						<ProductHistoryReport showModal={showReport} cancelcallback={() => setShowReport(false)} productInfo={product}></ProductHistoryReport>
					}


				</>
			}
			{isLoading && <div className="" style={{ marginTop : 120 }}>
				<Loading />
			</div>}
		</>
	);
};

const mapState = (state) => {
	const product = state.ProductReducer.product;
	const orisninfo = state.ProductReducer.orisninfo;
	const errmessage = state.AlertReducer.simplemessage;
	const isstock = state.ProgramReducer.productlistisstock || false;
	var user = state.AuthReducer.user;
	const clist = state.RayCodeReducer.items;
	return { product, isstock, orisninfo, errmessage, user, clist };
};

const mapDispatch = (dispatch) => ({
	GetProduct: (sn) => dispatch(ProductAction.GetProduct(sn)),
	GetProductOld: (sn, snobj) => dispatch(ProductAction.GetProductOld(sn, snobj)),
});

export default connect(mapState, mapDispatch)(AdminProductInfo);